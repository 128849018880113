import portal from './portal.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://chat.openai.com/g/g-665VpUeAF-portal"><img style={{ boxShadow: "10px 5px 5px black" }} src={portal} className="App-logo" alt="logo" /></a>
      </header>
    </div>
  );
}

export default App;
